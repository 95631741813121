import { Chartek } from '../model/chartek';
import httpClient from './http-client';

class GoogleService {
  getLatLng(address: string): Promise<Chartek> {
    return httpClient
      .get<{ results: any }>('https://maps.googleapis.com/maps/api/geocode/json', {
        address: address,
        key: 'AIzaSyC5hcXL6cgtyf1Q-bN3q73EdFRUQSeKCVk',
      })
      .then((r) => {
        return r.results;
      });
  }
}

const googleService = new GoogleService();
export default googleService;

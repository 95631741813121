import { API_ENDPONIT } from 'constants/index';
import { Chartek } from '../model/chartek';
import httpClient from './http-client';

class ChartekService {
  getChartek(uniqueId: string): Promise<Chartek> {
    return httpClient
      .get<{ data: Chartek }>(API_ENDPONIT.chartek_link, { unique_id: uniqueId })
      .then((r) => r.data);
  }
}

const chartekService = new ChartekService();
export default chartekService;

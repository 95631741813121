/* eslint-disable react-hooks/exhaustive-deps */
import LoadIndicator from 'devextreme-react/load-indicator';
import { createContext, useEffect, useState } from 'react';
import { Chartek } from '../model/chartek';
import { FlexRow } from 'components/shared/flex-row';
import { OrderStatus, Order } from 'model/order';
import Header from 'components/shared/header';
import Footer from 'components/shared/footer';
import ChartekForm from './chartek-form';
import ChartekComponent from 'components/chartek/chartek';
import ChartekInfo from 'components/chartek/chartek-info';
import chartekService from 'service/chartek-service';
import notify from 'devextreme/ui/notify';

export const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

export const ChartekOrderContext = createContext<any>({});

const ChartekView = () => {
  const query = useQuery();
  const [chartek, setChartek] = useState<Chartek | null>(null);
  const [statuses, setStatuses] = useState<OrderStatus[] | null>(null);
  const [orders, setOrders] = useState<Order[] | []>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const link = query.get('unique_id');
    if (link) {
      setLoading(true);
      chartekService
        .getChartek(link)
        .then((chartek: Chartek) => {
          setLoading(false);
          setChartek(chartek);
        })
        .catch((error) => {
          setLoading(false);
          setError(true);
        });
    }
  }, []);

  useEffect(() => {
    if (chartek) {
      if (chartek.folder) {
        setOrders(
          chartek.folder.Orders.sort((a: Order, b: Order) => {
            if (+a.sequence_no < +b.sequence_no) return -1;
            if (+a.sequence_no > +b.sequence_no) return 1;
            return 0;
          }),
        );
      }
      if (chartek.status) {
        setStatuses(chartek.status);
      }
    }
  }, [chartek]);

  const renderView = () => {
    if (error) {
      return (
        <FlexRow justifyContent="center">
          <h3>Link does not exist or is expired.</h3>
        </FlexRow>
      );
    }
    if (loading) {
      return (
        <FlexRow justifyContent="center" style={{ margin: '50px 0' }}>
          <LoadIndicator />
        </FlexRow>
      );
    }
    if (orders && statuses && chartek) {
      const { Orders, ...rest } = chartek?.folder;
      return (
        <ChartekOrderContext.Provider value={{ setOrders, orders, statuses }}>
          <ChartekInfo info={rest} />
          <ChartekComponent />
        </ChartekOrderContext.Provider>
      );
    }
    return <ChartekForm setChartek={setChartek} setLoading={setLoading} />;
  };

  return (
    <>
      <Header />
      {renderView()}
      <Footer />
    </>
  );
};

export default ChartekView;

import { stringify } from 'qs';

const baseURL = process.env.REACT_APP_API_BASE_URL || '';

class HttpClient {
  get<T>(url: string, query: any): Promise<T> {
    let requestURI = url;

    if (!url.startsWith('http') && !url.startsWith('//')) {
      if (url.startsWith('/')) {
        requestURI = baseURL + url;
      } else {
        requestURI = `${baseURL}/${url}`;
      }
    }

    const urlQuery = stringify(query, { addQueryPrefix: true, encode: false });
    return fetch(`${requestURI}${urlQuery}`).then((r) => r.json());
  }
  put<T>(url: string, body: any): Promise<T> {
    let requestURI = url;

    if (!url.startsWith('http') && !url.startsWith('//')) {
      if (url.startsWith('/')) {
        requestURI = baseURL + url;
      } else {
        requestURI = `${baseURL}/${url}`;
      }
    }
    const requestHeaders: HeadersInit = new Headers();
    requestHeaders.set('Content-Type', 'application/x-www-form-urlencoded');
    let bodyData;

    if (body) {
      bodyData = Object.keys(body)
        .map((key) => `${key}=${encodeURIComponent(body[key])}`)
        .join('&');
    }
    return fetch(`${requestURI}`, {
      method: 'PUT',
      headers: requestHeaders,
      body: bodyData,
    }).then((r) => {
      return r.json();
    });
  }
}

const httpClient = new HttpClient();
export default httpClient;

import { InlinePopup, ButtonStyle } from '../styles';
import { Position, Offset } from 'devextreme-react/popup';
import { useContext, useEffect, useState } from 'react';
import { Order, OrderStatus } from 'model/order';
import notify from 'devextreme/ui/notify';
import orderService from 'service/order-service';
import { ChartekOrderContext } from 'screens/chartek-view';
import coordService from 'service/coord-service';
import { OrderContext } from '../chartek';

const ChangeStatusBtn = ({ offset, pos }: any) => {
  const { statuses, order, handleAction } = useContext(OrderContext);
  const { orders, setOrders } = useContext(ChartekOrderContext);

  const [showStatusChange, setShowStatusChange] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [orderStatuses, setOrderStatuses] = useState<OrderStatus[] | []>([]);
  const [position, setPosition] = useState<string>('');
  const [ofs, setOffset] = useState({
    x: 0,
    y: 0,
  });

  const statusLength = 50 * orderStatuses.length - orderStatuses.length * 4;

  useEffect(() => {
    if (showStatusChange) {
    }
  }, [pos]);

  const changeStatus = (id: number) => {
    setSubmitted(true);
    orderService.updateStatus(order.unique_id, id).then((response) => {
      if (response.success) {
        notify('You have successfully updated order status.', 'success');
        const myOrders = [...orders];
        const findOrderIndex = orders.findIndex((it: Order) => it.unique_id === order.unique_id);
        const findStatus = statuses.find((st: OrderStatus) => st.id === id);
        myOrders[findOrderIndex].Status = findStatus;
        myOrders[findOrderIndex].status = findStatus.status_text;
        setOrders(myOrders);
        setShowStatusChange(false);
        setSubmitted(false);
        handleAction('swipe', false);
        handleAction('menu', false);
      }
    });
  };

  useEffect(() => {
    if (order?.Status?.status_text === 'DELIVERED') {
      setOrderStatuses([order.Status]);
    } else {
      const higherPriorities = statuses
        .filter((it: OrderStatus) => order?.Status?.priority <= it.priority)
        .sort((it: OrderStatus) => it.priority);
      const lowerPriorities = statuses
        .filter((it: OrderStatus) => order?.Status?.priority > it.priority)
        .sort((it: OrderStatus) => it.priority);

      const priorities = [];
      if (lowerPriorities.length > 0) {
        priorities.push(lowerPriorities[lowerPriorities.length - 1]);
      }
      priorities.push(...higherPriorities);

      if (priorities) setOrderStatuses(priorities);
    }
  }, [statuses]);

  const handleShow = () => {
    if (!showStatusChange) {
      const coord = coordService.getCoords(document.getElementById(`button-change-status-${order.id}`));

      const posArray = pos.split(' ');

      if (coord.top < window.innerHeight / 2) {
        setPosition(`${posArray[0]} top`);
        const offsetY = posArray[0] === 'right' ? -48 : offset.y;
        setOffset({ x: offset.x, y: offsetY + 30 });
      } else {
        const y = orderStatuses.length === 1 ? 22 : 30;
        setPosition(`${posArray[0]} bottom`);
        setOffset({ x: offset.x, y: offset.y - y });
      }
      setShowStatusChange(true);
    } else {
      setShowStatusChange(false);
    }
  };

  return (
    <>
      <ButtonStyle
        style={{
          borderRadius: '25px',
          alignSelf: 'center',
          backgroundColor: pos.includes('center') ? order?.Status?.color : '#F1F1F1',
          color: pos.includes('center') ? '#fff' : '#555555',
        }}
        onClick={handleShow}
        id={`button-change-status-${order?.id}`}
      >
        {submitted ? 'Changing' : 'Change Status'}
      </ButtonStyle>
      <InlinePopup
        visible={showStatusChange}
        onHiding={() => setShowStatusChange(false)}
        closeOnOutsideClick={true}
        showTitle={false}
        fullScreen={false}
        width={140}
        height={statusLength}
        focusStateEnabled={false}
      >
        <Position of={`#button-change-status-${order?.id}`} my={position}>
          {offset && <Offset x={ofs.x} y={ofs.y} />}
        </Position>
        {orderStatuses?.length > 0 &&
          orderStatuses.map((it: OrderStatus) => (
            <ButtonStyle
              style={{
                borderRadius: '25px',
                marginBottom: '10px',
                backgroundColor: it?.color,
                color: '#fff',
                fontWeight: 900,
              }}
              className={it.id === order?.Status?.id ? 'btn-active mb-1' : 'mb-1'}
              key={`${it?.id}-${order?.id}`}
              onClick={() => changeStatus(it?.id)}
            >
              {it?.status_alias}
            </ButtonStyle>
          ))}
      </InlinePopup>
    </>
  );
};

export default ChangeStatusBtn;

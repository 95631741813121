import { FlexRow } from './flex-row';

const Header = () => (
  <FlexRow style={{ color: '#fff', background: '#FAA61A', padding: '10px', fontSize: '20px', fontWeight: 600 }}>
    <div>Driver instructions</div>
    <div></div>
  </FlexRow>
);

export default Header;

import { API_ENDPONIT } from 'constants/index';
import { Chartek } from '../model/chartek';
import httpClient from './http-client';

class OrderService {
  updateStatus(id: number, status_id: number): Promise<any> {
    return httpClient
      .put<{ data: any }>(`externaldriverintegration/order/${id}`, { status_id: status_id })
      .then((r) => r);
  }
}

const orderService = new OrderService();
export default orderService;

import { useState } from 'react';
import { Order } from 'model/order';
import { ButtonLink, PopupNoPadding } from '../styles';
import GoogleMapReact from 'google-map-react';
import googleService from 'service/google-service';
import { alert } from 'devextreme/ui/dialog';

interface MapDetailType {
  data: Order;
  field: 'from' | 'to';
}

type LatlngType = {
  lat: number;
  lng: number;
};

const AnyReactComponent = ({ text }: any) => (
  <div>
    <img src={`${process.env.PUBLIC_URL}/images/ggl-marker.png`} width={30} />
  </div>
);

const MapDetail = ({ data, field }: MapDetailType) => {
  const [showMap, setShowMap] = useState(false);
  const [latlng, setLatLng] = useState<LatlngType | null>(null);

  const handleShowMap = () => {
    const zipcode = data[`${field}_zipcode`];
    const address1 = data[`${field}_address1`];
    const city = data[`${field}_city`];
    const country = data[`${field}_country`];
    const adr = `${zipcode}+${address1}+${city}+${country}`;
    googleService.getLatLng(adr).then((results: any) => {
      let error = false;
      if (results[0]) {
        const { geometry } = results[0];
        if (geometry?.location) {
          setLatLng(geometry.location);
          setShowMap(true);
        } else {
          error = true;
        }
      } else {
        error = true;
      }
      if (error) {
        alert('Invalid address', 'Invalid address');
      }
    });
  };
  return (
    <>
      <ButtonLink onClick={handleShowMap}>
        <img src={`${process.env.PUBLIC_URL}/images/ggl-marker.png`} width={15} />
      </ButtonLink>
      <PopupNoPadding visible={showMap} showTitle={false} closeOnOutsideClick={true} onHiding={() => setShowMap(false)}>
        <div style={{ height: '100%', width: '100%' }}>
          {latlng && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyC5hcXL6cgtyf1Q-bN3q73EdFRUQSeKCVk' }}
              defaultCenter={latlng}
              defaultZoom={11}
            >
              <AnyReactComponent lat={latlng?.lat || 0} lng={latlng?.lng || 0} text="ABC" />
            </GoogleMapReact>
          )}
        </div>
      </PopupNoPadding>
    </>
  );
};

export default MapDetail;

import { FlexRow } from 'components/shared/flex-row';
import moment from 'moment';
import { useScreenSize } from 'service/query-media';
import styled from 'styled-components';
import { ChartekComponentWrapper } from './styles';

const ChartekInfo = ({ info }: any) => {
  const screenSize = useScreenSize();
  const direction = screenSize.isXSmall ? 'column' : 'row';
  const justifyContent = screenSize.isXSmall ? 'flex-start' : 'space-between';
  const alignItems = screenSize.isXSmall ? 'baseline' : 'space-between';
  return (
    <>
      <ChartekComponentWrapper>
        <FlexRow justifyContent={justifyContent} alignItems={alignItems}>
          <FlexRow justifyContent="flex-start" direction={direction}>
            <Label>Chartek: </Label>
            {info.trip}
          </FlexRow>
          <FlexRow justifyContent="flex-start" direction={direction}>
            <Label>Vehicle: </Label>
            {info.truck}
          </FlexRow>
          <FlexRow justifyContent="flex-start" direction={direction}>
            <Label>Trailer: </Label>
            {info.trailer}
          </FlexRow>
          <FlexRow justifyContent="flex-start" direction={direction}>
            <Label>Disponent: </Label>-
          </FlexRow>
        </FlexRow>
        <FlexRow justifyContent={justifyContent} alignItems={alignItems}>
          <FlexRow justifyContent="flex-start" direction={direction}>
            <Label>Date: </Label>
            {moment(info.min_time).format('DD-MM-YYYY')}
          </FlexRow>
          <FlexRow justifyContent="flex-start" direction={direction}>
            <Label>Driver: </Label>
            {info.driver}
          </FlexRow>
          <FlexRow justifyContent="flex-start"></FlexRow>
          <FlexRow justifyContent="flex-start" />
        </FlexRow>
      </ChartekComponentWrapper>
    </>
  );
};

const Label = styled.span`
  width: 75px;
`;
export default ChartekInfo;

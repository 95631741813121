import { FlexRow } from 'components/shared/flex-row';
import TextBox from 'devextreme-react/text-box';
import Button from 'devextreme-react/button';
import { NativeEventInfo } from 'devextreme/events';
import { FormEvent, useState } from 'react';
import dxTextBox from 'devextreme/ui/text_box';
import Validator from 'devextreme-react/validator';
import { RequiredRule } from 'devextreme-react/data-grid';
import chartekService from 'service/chartek-service';
import { Chartek } from 'model/chartek';
import notify from 'devextreme/ui/notify';

const ChartekForm = ({ setChartek, setLoading }: any) => {
  const [id, setId] = useState('');

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    chartekService
      .getChartek(id)
      .then((chartek: Chartek) => {
        setChartek(chartek);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify('Link does not exist or is expired.', 'error');
      });
  };

  return (
    <form onSubmit={onSubmit}>
      <FlexRow justifyContent="center">
        <div className="dx-fieldset text-center">
          <div className="mb-1">Please enter chartek ID</div>
          <div className="mb-1">
            <TextBox
              width={300}
              value={id}
              onValueChanged={(e: NativeEventInfo<dxTextBox>) => {
                setId(e.component.option('text'));
              }}
            >
              <Validator>
                <RequiredRule message="Please enter chartek link" />
              </Validator>
            </TextBox>
          </div>
          <div style={{ textAlign: 'center' }}>
            <Button
              id="button"
              text="Go to chartek detail"
              type="default"
              useSubmitBehavior={true}
              style={{ fontWeight: 600 }}
            />
          </div>
        </div>
      </FlexRow>
    </form>
  );
};

export default ChartekForm;

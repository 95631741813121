import { createContext, useContext, useState } from 'react';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useMemo } from 'react';
import { ChartekComponentWrapper } from './styles';
import OrderDetail from './order-detail';
import { ChartekOrderContext } from 'screens/chartek-view';

export const OrderContext = createContext<any>({});
const ChartekComponent = () => {
  const { orders, statuses } = useContext(ChartekOrderContext);
  const [activeMenu, setActiveMenu] = useState<number | false>(false);
  const [swipeOrder, setSwipe] = useState<number | false>(false);
  const [activeInfo, setActiveInfo] = useState<number | false>(false);
  const [activeColli, setActiveColli] = useState<number | false>(false);

  const dataSource = useMemo(() => {
    return new DataSource({
      store: new CustomStore({
        key: 'id',
        load: async () => {
          return {
            data: orders,
            success: true,
            totalCount: orders.length || 0,
          };
        },
        update: async () => {},
        remove: async () => {},
      }),
    });
  }, [orders]);

  const handleAction = (type: string, value: number | false) => {
    switch (type) {
      case 'swipe':
        setSwipe(value);
        if (value) {
          setActiveColli(false);
        }
        break;
      case 'menu':
        setActiveMenu(value);
        break;
      case 'colli':
        setActiveColli(activeColli === value ? false : value);
        break;
      case 'info':
        setActiveInfo(activeInfo === value ? false : value);
        break;
    }
  };

  const datagridRowRender = (rowInfo: any) => {
    return (
      <OrderContext.Provider
        value={{
          order: rowInfo.data,
          activeMenu: activeMenu,
          swipeOrder: swipeOrder,
          activeInfo: activeInfo,
          activeColli: activeColli,
          statuses: statuses,
          orderLength: orders?.length,
          handleAction: handleAction,
        }}
      >
        <OrderDetail />
      </OrderContext.Provider>
    );
  };

  return (
    <ChartekComponentWrapper>
      <DataGrid
        dataSource={dataSource}
        showBorders={false}
        showColumnLines={false}
        rowRender={datagridRowRender}
        height={window.innerHeight - 160}
      >
        <Column dataField="sequence_no" caption="Pos" dataType="string" width={'6%'}></Column>
        <Column dataField="order_number_display" caption="Reference" dataType="number" width={'12%'}></Column>
        <Column dataField="Collis" caption="Collis" width={'8%'}></Column>
        <Column dataField="Dimension" caption="" width={'15%'}></Column>
        <Column dataField="pickup" caption="" width={'5%'}></Column>
        <Column dataField="from_name" caption="Pick-up" width={'16.3%'}></Column>
        <Column dataField="delivery" caption="" width={'5%'}></Column>
        <Column dataField="to_name" caption="Delivery" width={'16.3%'}></Column>
        <Column dataField="buttons" caption="" width={'16.3%'} />
      </DataGrid>
    </ChartekComponentWrapper>
  );
};

export default ChartekComponent;
